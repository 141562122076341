import React from "react";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import Layout from "../components/Layout/Layout";


const NotFoundPage = () => {
  return (
    <Layout
      title="404: Page Not Found"
    >
      <Header
        colorScheme="light"
      />

      <Hero
        title="404: PAGE NOT FOUND"
        subtitle="You just tried to access a page that does not exist."
        colorScheme="light"
      />

      <Footer />
    </Layout>
  );
}

export default NotFoundPage;
